import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("staging")
) {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_STAGING_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_STAGING_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_STAGING_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STAGING_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
      process.env.REACT_APP_STAGING_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_STAGING_FIREBASE_APP_ID,
  };
}

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const fn = firebase.functions();
const sto = firebase.storage();
const fb = {
  auth: auth,
  db: db,
  functions: fn,
  storage: sto,
};

export default fb;
