import "../../App.css";
import "firebase/firestore";
import "firebase/auth";
import Link from "./Link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fb from "../../firebase.config";
import React from "react";
import Logo from "./Logo";

var highWeight = -9999;
var lowWeight = 9999;


const getImportantLinkWeight = () => {
  const diff = highWeight - lowWeight;
  var importantWeight = lowWeight + (diff * 0.70);
  if(importantWeight <= 0){
    importantWeight = 10000;
  }
  console.log("highWeight is " + highWeight);
  console.log("lowWeight is " + lowWeight);
  console.log("diff is " + diff);
  console.log("importantWeight is " + importantWeight);
  return importantWeight;
}

const getDayName = (dateStr, locale) => {
  let date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "long" });
};

let upperCols = [[], []];
let headlineCol = [];
let lowerCols = [[], [], []];

const alreadyAddedLink = (link) => {
  for(const childArray of upperCols){
    for(const arrayLink of childArray){
      if(arrayLink.id == link.id){
        //console.log("link " + link.id + " already added to upperCols... continue to next index.");
        return true;
      }
    }
  }
  for(const arrayLink of headlineCol){
    if(arrayLink.id == link.id){
      //console.log("link " + link.id + " already added to headline... continue to next index.");
      return true;
    }
  }
  for(const childArray of lowerCols){
    for(const arrayLink of childArray){
      if(arrayLink.id == link.id){
        //console.log("link " + link.id + " already added to lowerCols... continue to next index.");
        return true;
      }
    }
  }
  return false;
}

const childArrayWithLeastElements = (parentArray) => {
  let holder = [];
  for (let i = 0; i < parentArray.length; i++) {
    let childArray = parentArray[i];
    holder[i] = childArray.length;
  }
  let minItemCount = 999;
  let minItemIndex = 0;
  for (let i = 0; i < holder.length; i++) {
    let itemCount = holder[i];
    if (itemCount < minItemCount) {
      minItemCount = itemCount;
      minItemIndex = i;
    }
  }
  return minItemIndex;
};

const getUserIsEditor = async () => {
  const currentUser = fb.auth.currentUser;
  if (!currentUser?.uid) return false;

  const query = await fb.db.collection("users").doc(currentUser.uid).get();

  const roles = await query.data().roles;
  console.log(roles);

  return roles.includes("editor") || roles.includes("admin");
};

const fetchLinks = async () => {
  let ref = fb.db.collection("links");
  let snapshot = await ref
    .where("reviewState", "==", "approved")
    .orderBy("weight", "desc")
    .limit(50)
    .get();
  if (!snapshot.empty) {
    return snapshot.docs.map((doc) => doc);
  }
  return [];
};

const fetchLatestEdition = async (latestPublished = false) => {
  let ref = fb.db.collection("editions");
  let snapshot = await ref
    .orderBy("createdAt", "desc")
    .limit(7)
    .get();

    if (!snapshot.empty) {
      for(const edition of snapshot.docs){
          if(latestPublished){
              if(edition.data().state === "published"){
                  return edition;
              }else{
                  continue;
              }
          }else{
              return edition;
          }
      }
  }
  return null;
};

const fetchStoriesForEdition = async (editionID) => {
  try {
    const storiesSnapshot = await fb.db.collection('editions')
        .doc(editionID)
        .collection('stories')
        .orderBy('weight', 'desc')
        .get();
    const stories = storiesSnapshot.docs.map(doc => doc);
    return stories;
  } catch (error) {
      console.error('Error fetching stories:', error);
      throw error;
  }
}

class GetLinks extends React.Component {

  constructor(props) {
    super(props);
    this.latestEdition = null;
    this.rawLinks = [];
    this.regularLinks = [];
    this.headlineLinks = [];
    this.topics = {};
    this.isCurrentUserEditor = false;
    this.print = false;
  }

  async componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);
    this.print = urlParams.get('p') === 'true';
    
    this.latestEdition = await fetchLatestEdition(true);
    if(this.latestEdition){
      this.rawLinks = await fetchStoriesForEdition(this.latestEdition.id);
    }else{
      this.rawLinks = await fetchLinks();
    }
    this.isCurrentUserEditor = await getUserIsEditor();
    this.setState({
      rawLinks: this.rawLinks,
      isCurrentUserEditor: this.isCurrentUserEditor,
      latestEdition: this.latestEdition,
      print: this.print
    });
  }

  render() {

    ///~~~~~~~~~~~~~~ ADD LINK
    /*
    for (let k = 0; k < this.rawLinks.length; k++) {

      let linkToAdd = this.rawLinks[k];

      // If link is a headline...
      if (linkToAdd.data().isHeadline) {
        let canAdd = true;
        for (let i = 0; i < this.headlineLinks.length; i++) {
          let headlineLink = this.headlineLinks[i];
          if (linkToAdd.id === headlineLink.id) {
            canAdd = false;
          }
          if(!linkToAdd.data().imageURL){
            console.error("null imageURL for link " + linkToAdd.id);
            canAdd = false;
          }
        }
        if (canAdd) {
          this.headlineLinks.push(linkToAdd);
          this.headlineLinks.sort((a, b) =>
            a.data().imageURL.length < b.data().imageURL.length ? 1 : -1
          );
          continue;
        }
      }
      // If link is in a topic
      if (linkToAdd.data().topic.length > 0) {
        if (
          this.topics[linkToAdd.data().topic] != null &&
          this.topics[linkToAdd.data().topic] !== undefined
        ) {
          let canAdd = true;
          let thisTopic = this.topics[linkToAdd.data().topic];
          for (let i = 0; i < thisTopic.links.length; i++) {
            let topicLink = thisTopic.links[i];
            if (linkToAdd.id === topicLink.id) {
              canAdd = false;
            }
          }
          if (canAdd) {
            thisTopic.links.push(linkToAdd);
            let totalWeight = 0;
            for (let h = 0; h < thisTopic.links.length; h++) {
              let thisTopicLink = thisTopic.links[h];
              totalWeight = totalWeight + thisTopicLink.data().weight;
            }
            thisTopic.weight = totalWeight / thisTopic.links.length;
            this.topics[thisTopic.name] = thisTopic;
            continue;
          }
        } else {
          let thisTopic = {
            name: linkToAdd.data().topic,
            links: [],
            weight: linkToAdd.data().weight,
          };
          thisTopic.links.push(linkToAdd);
          this.topics[thisTopic.name] = thisTopic;
          continue;
        }
      }
      // Otherwise add it to the regular links
      let canAdd = true;
      for (let i = 0; i < this.regularLinks.length; i++) {
        let regularLink = this.regularLinks[i];
        if (linkToAdd.id === regularLink.id) {
          canAdd = false;
        }
      }
      if (canAdd) {
        this.regularLinks.push(linkToAdd);
      }
    }
    ///~~~~~~~~~~~~~~~~~~~~~~~~ LAYOUT LINK
    let links = this.regularLinks;


    // Add Headlines
    let offset = 4;
    if (links.length <= offset) {
      offset = 0;
    }

    links.splice(offset, 0, ...this.headlineLinks);

    // Add Links in a Topic
    for (let key in this.topics) {
      let topic = this.topics[key];
      let insertIndex = 0;
      for (let j = 0; j < links.length; j++) {
        let link = links[j];
        if (link.data().weight > topic.weight || link.data().isHeadline) {
          continue;
        }
        insertIndex = j;
        break;
      }
      links.splice(insertIndex, 0, ...topic.links);
    }
    ///~~~~~~~~~~~~~~~~~~~~~~~~

    */

    for (let i = 0; i < this.rawLinks.length; i++) {
      let link = this.rawLinks[i];
      //console.log("index == " + i);
      if(alreadyAddedLink(link)){
        continue;
      }

      if(link.data().weight > highWeight){
        highWeight = link.data().weight;
      }
      if(link.data().weight < lowWeight){
        lowWeight = link.data().weight;
      }

      if (link.data().isHeadline) {
        headlineCol.push(link);
        //console.log("index " + i + " pushed " + link.id + " (" + link.data().weight + " to headline cols");
        i = 0;
        continue;
      }else{
        if (upperCols.length < 2) {
          if(this.print == false){
            let indexOfSmallest = childArrayWithLeastElements(upperCols);
            upperCols[indexOfSmallest].push(link);
            //console.log("index " + i + " pushed " + link.id + " (" + link.data().weight + " to upper cols " + indexOfSmallest);
            i = 0;
            continue;
          }
        } else {
          let indexOfSmallest = childArrayWithLeastElements(lowerCols);
          lowerCols[indexOfSmallest].push(link);
          //console.log("index " + i + " pushed " + link.id + " (" + link.data().weight + " to lower cols " + indexOfSmallest);
          i = 0;
          continue;
        }
      }
    }

    let date = new Date();
    if(this.latestEdition){
      date = this.latestEdition.data().createdAt.toDate();
    }
    let dayName = getDayName(date.toString());
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthNameIndex = date.getMonth();

    return (
      <div>
        <Row className="" style={{ paddingTop: "20px" }}>
          <Col className="frontpage-column" md={6}>
            {upperCols[0].map((doc) => (
              <Link link={doc} showEdit={this.isCurrentUserEditor} print={this.print} importantWeight={getImportantLinkWeight()} />
            ))}
          </Col>
          <Col md={6}>
            {upperCols[1].map((doc) => (
              <Link link={doc} showEdit={this.isCurrentUserEditor} print={this.print} importantWeight={getImportantLinkWeight()} />
            ))}
          </Col>
        </Row>
        <Row className="">
          <Col
            md={12}
            style={{
              textAlign: "center",
              textJustify: "inter-word",
              scrollPadding: "15px",
            }}
          >
            {headlineCol.map((doc) => (
              <Link link={doc} showEdit={this.isCurrentUserEditor} print={this.print} importantWeight={getImportantLinkWeight()} />
            ))}
          </Col>
          <Col
            md={12}
            style={{ textAlign: "center", textJustify: "inter-word" }}
          >
            <Logo isMobile={false} />
            {dayName} {monthNames[monthNameIndex]} {date.getDate()},{" "}
            {date.getFullYear()}
            <br />
            <br />
          </Col>
        </Row>
        <Row className="">
          <Col className="frontpage-column" md={4}>
            {lowerCols[0].map((doc) => (
              <Link link={doc} showEdit={this.isCurrentUserEditor} print={this.print} importantWeight={getImportantLinkWeight()} />
            ))}
          </Col>
          <Col className="frontpage-column" md={4}>
            {lowerCols[1].map((doc) => (
              <Link link={doc} showEdit={this.isCurrentUserEditor} print={this.print} importantWeight={getImportantLinkWeight()} />
            ))}
          </Col>
          <Col md={4}>
            {lowerCols[2].map((doc) => (
              <Link link={doc} showEdit={this.isCurrentUserEditor} print={this.print} importantWeight={getImportantLinkWeight()} />
            ))}{" "}
          </Col>
        </Row>
      </div>
    );
  }
}
export default GetLinks;