import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import fb from "../../firebase.config";
import Dropzone from "./Dropzone";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";

const getLink = async (id) => {
  const query = await fb.db.collection("links").doc(id).get();

  return query.data();
};

const EditLinkForm = () => {
  const [currentLinkData, setCurrentLinkData] = useState({});
  const [newLinkData, setNewLinkData] = useState({});
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const data = await getLink(id);
      setCurrentLinkData(data);
    })();
  }, [id]);

  const onChange = (e) => {
    setNewLinkData((p) => ({
      ...p,
      [e.target.id]: e.target.value,
    }));
  };

  const onCheckBox = (e) => {
    setNewLinkData((p) => ({
      ...p,
      [e.target.id]: e.target.checked,
    }));
  };

  const onDropAccepted = (e) => {
    setFile(e[0]);
  };

  const onDropRejected = (e) => {
    const { errors } = e[0];
    errors.forEach((e) => alert(e.message));
  };

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      const currentUser = fb.auth.currentUser;
      e.preventDefault();
      let fileUrl;
      if (file) {
        const storageRef = fb.storage.ref("/public/link_images/" + uuidv4());
        const { ref } = await storageRef.put(file);
        fileUrl = await ref.getDownloadURL();
      }
      const updateLink = fb.functions.httpsCallable("updateLink");

      await updateLink({
        title: currentLinkData.title,
        imageURL: imageDeleted ? "" : fileUrl || currentLinkData.imageURL || "",
        topicID: currentLinkData.topicID,
        linkID: id,
        userID: currentUser?.uid,
        chomp: currentLinkData.chomp,
        isHeadline: currentLinkData.isHeadline,
        ...newLinkData,
      });
      toast.success("Link updated");
    } catch (e) {
      toast.error("The link could not be updated. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const [imageDeleted, setImageDeleted] = useState(false);

  const onDeleteImageClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setNewLinkData((p) => ({ ...p, imageURL: "" }));
    setImageDeleted(true);
  };

  return (
    <div className="container m-0 mt-5">
      <h2 className="mb-3">
        <i className="fa fa-pencil-square-o mr-1" aria-hidden="true"></i>
        Edit link
      </h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Link title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            defaultValue={currentLinkData.title}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">
            Content
          </label>
          <textarea
            className="form-control"
            id="content"
            rows="3"
            defaultValue={currentLinkData.content}
            onChange={onChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <Dropzone
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            selectedUrl={!imageDeleted ? currentLinkData.imageURL : null}
            onDeleteClick={onDeleteImageClick}
          />
        </div>
        <div className="mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={currentLinkData.isHeadline}
              id="isHeadline"
              onChange={onCheckBox}
            />
            <label className="form-check-label" htmlFor="isHeadline">
              Make headline
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg"
          disabled={loading}
        >
          {loading ? "Loading..." : "Save"}
        </button>
      </form>
    </div>
  );
};

export default EditLinkForm;
