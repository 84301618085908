import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class LinkContent extends React.Component {

  getImageContent(link){
    if(link.data().imageURL.length > 0){
      return(
        <span>
          <img
            className="link-image"
            src={link.data().imageURL}
            style={{ maxWidth: "100%", maxHeight: "300px" }}
            alt=""
          />
          <br/>
        </span>
      );
    }else{
      return(
        <span/>
      );
    }
  }

  render() {
    const link = this.props.link;
    const destination = link.data().destination;
    const userURL = "/u/" + link.data().userID;
    const imageContent = this.getImageContent(link);

    if (link.data().content.length === 0) {
      window.location = destination;
      return <div></div>;
    }

    // Redirect for an ad for which all content was visible on the home page
    if (link.data().type === "ad" && link.data().content.length <= 150) {
      window.location = destination;
    }

    return (
      <div className="user-detail">
        <NavigationBar />
        <Container className="hero">
          <Button href="/" variant="link" size="sm">
            &lt; Back
          </Button>
          <br />
          <Jumbotron>
            <Row>
              <Col md={12} style={{ textAlign: "left" }}>
                {imageContent}
                <span style={{ fontSize: "24pt" }}>{link.data().title}</span>
                <br />
                <span style={{ fontSize: "18" }}>{link.data().content}</span>
                <br />
                <Button
                  href={link.data().url}
                  target="_blank"
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    window.open(destination, "_blank");
                  }}
                >
                  Read the full thing
                </Button>
              </Col>
            </Row>
          </Jumbotron>
          {link.data().userID.length > 0 && (
            <Row>
              <Col md={12}>
                Curator:{" "}
                <a href={userURL}>
                  <Image src={link.data().userPhotoURL} width="40px" rounded />
                </a>
                &nbsp;<a href={userURL}>{link.data().userName}</a>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

export default LinkContent;
