import React from "react";
import Navbar from "react-bootstrap/Navbar";

class NavigationBar extends React.Component {
  render() {
    return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="https://coinchomp.com">
          <img src="/images/logo-white.png" width="150" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavigationBar;
