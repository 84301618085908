import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import fb from '../../firebase.config';

class BlogMenu extends React.Component {

    newPost(){
        const button = document.getElementById('newPostButton');
        if(fb.auth.currentUser){
            button.innerText = "Creating...";
            button.innerHtml = "Creating...";
            const user = fb.auth.currentUser;
            console.log("newPost user exists = " + user.uid);
            console.log("creating new post...");
            var createBlogPost = fb.functions.httpsCallable('createBlogPost');
            createBlogPost({ userID: user.uid }).then((result) => {
                console.log("%j", result);
                window.location.href = '/edit-post/' + result.data.postID; 
            });
        }else{
            console.log("newPost user DOES NOT exist");
        }
    }

    componentDidMount() {
        console.log("mounted");
    }

 render() {

    return (
        <Row style={{paddingTop:"4px", paddingBottom:"10px"}}>
            <Col md={12}>
                <ButtonGroup size="sm" className="mb-2">
                    <Button id="newPostButton" onClick={ this.newPost.bind(this) }>New Post</Button>
                    <Button onClick={ () => { window.location.href="/posts" } }>Your Posts</Button>
                    <Button onClick={ () => { window.location.href="/deleted-posts" } }>Post Trash</Button>
                </ButtonGroup>
            </Col>
        </Row>
    );
  }
}

export default BlogMenu;