import "./App.css";
import Home from "./components/home/Home";
import About from "./components/pages/About";
import GetLinkContent from "./components/content/GetLinkContent";
import GetStoryContent from "./components/content/GetStoryContent";
import GetUserDetail from "./components/user/GetUserDetail";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Partners from "./components/pages/Partners";
import BusinessInquiries from "./components/pages/BusinessInquiries";
import Donate from "./components/pages/Donate";
import Books from "./components/pages/Books";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Signup from "./components/auth/Signup";
import BlogHome from "./components/blog/BlogHome";
import EditPost from "./components/blog/EditPost";
import ViewPost from "./components/blog/ViewPost";
import UserPosts from "./components/blog/UserPosts";
import PostTrash from "./components/blog/PostTrash";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/account/Profile";
import SetUsername from "./components/account/SetUsername";
import SetDisplayName from "./components/account/SetDisplayName";
import AffiliateRedirect from "./components/affiliate/AffiliateRedirect";
import TermsOfUse from "./components/pages/TermsOfUse";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.scss";
import EditLink from "./components/editLink";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route path="/c/*">
            <GetLinkContent />
          </Route>
          <Route path="/e/*">
            <GetStoryContent />
          </Route>
          <Route exact path="/terms">
            <TermsOfUse />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/partners">
            <Partners />
          </Route>
          <Route exact path="/business-inquiries">
            <BusinessInquiries />
          </Route>
          <Route
            exact
            path="/services"
            component={() => {
              window.location.href =
                "https://docs.google.com/spreadsheets/d/1iywBKGnZX_koF66591JeWH_huXEUWo2FNsWd-CWpwJ0";
              return null;
            }}
          />
          <Route exact path="/donate">
            <Donate />
          </Route>
          <Route exact path="/books">
            <Books />
          </Route>

          <Route exact path="/deleted-posts/" component={PostTrash}></Route>
          <Route exact path="/posts/" component={UserPosts}></Route>
          <Route exact path="/edit-post/:id" component={EditPost}></Route>

          <Route exact path="/blog/" component={BlogHome}></Route>
          <Route exact path="/blog/:slug" component={ViewPost}></Route>

          <Route exact path="/profile/" component={Profile}></Route>
          <Route exact path="/set-username/" component={SetUsername}></Route>
          <Route
            exact
            path="/set-display-name/"
            component={SetDisplayName}
          ></Route>

          <Route
            exact
            path="/links"
            component={() => {
              window.location.href = "https://stanwith.me/coinchomp";
              return null;
            }}
          />

          <Route exact path={"/edit-link/:id"} component={EditLink} />

          <Route
            exact
            path="/podcast"
            component={() => {
              window.location.href = "https://podcast.coinchomp.com";
              return null;
            }}
          />
          <Route
            exact
            path="/podcast/rss"
            component={() => {
              window.location.href = "https://coinchomp.com";
              return null;
            }}
          />
          <Route
            path="/discord"
            component={() => {
              window.location.href = "https://discord.gg/jxscpsdRUB";
              return null;
            }}
          />
          <Route
            path="/live"
            component={() => {
              window.location.href =
                "https://www.youtube.com/c/CoinChomp?sub_confirmation=1";
              return null;
            }}
          />
          <Route
            path="/telegram"
            component={() => {
              window.location.href = "https://t.me/coinchomp";
              return null;
            }}
          />
          <Route
            path="/start"
            component={() => {
              window.location.href = "https://stanwith.me/coinchomp";
              return null;
            }}
          />
          <Route
            path="/shop"
            component={() => {
              window.location.href = "https://coinchomp.com";
              return null;
            }}
          />

          <Route exact path="/go/*">
            <AffiliateRedirect />
          </Route>
          <Route exact path="/u/*">
            <GetUserDetail />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
