import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import fb from "../../firebase.config";

import { BrowserView, MobileView } from "react-device-detect";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.busy = false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.busy = true;
    this.setState({ busy: true });
    const email = document.getElementById("SignupForm.Email").value;
    const password = document.getElementById("SignupForm.Password").value;
    fb.auth.createUserWithEmailAndPassword(email, password).then((cred) => {
      this.busy = false;
      this.setState({ busy: false });
      if ("user" in cred) {
        window.location = "/dashboard";
      }
    });
  };

  render() {
    document.title = "Signup | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={4} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Signup</h2>

              <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-4" controlId="SignupForm.Email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="eric@coinchomp.com"
                    size="md"
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="SignupForm.Password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" size="md" required={true} />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={this.busy}>
                  {this.busy ? "Signing Up..." : "Sign Up"}
                </Button>
              </Form>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Signup;
