import "../../App.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import LinkContent from "./LinkContent";
import Footer from "../navigation/Footer";

import { useDocument } from "react-firebase-hooks/firestore";

const LinkHook = (linkID) => {
  const [value, loading, error] = useDocument(
    firebase.firestore().doc("links/" + linkID),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  return (
    <div>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span style={{ opacity: 0.25 }}>Loading...</span>}
      {value && <LinkContent link={value} />}
    </div>
  );
};

function GetLinkContent() {
  var url = window.location.href;
  var urlParts = url.split("/");
  const linkID = urlParts.slice(-1)[0];

  return (
    <div className="App">
      {LinkHook(linkID)}
      <Footer />
    </div>
  );
}

export default GetLinkContent;
