import React from "react";
import Container from "react-bootstrap/Container";
import GetAllPosts from "./GetAllPosts";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import NavigationBar from "../navigation/NavigationBar";

import { BrowserView, MobileView } from "react-device-detect";

class BlogHome extends React.Component {
  componentDidMount() {
    console.log("mounted");
  }

  render() {
    return (
      <div className="App">
        <Container>
          <NavigationBar />
          <BrowserView>
            <h1>Posts</h1>
            <GetAllPosts />
            <Footer />
          </BrowserView>
          <MobileView>
            <h1>Posts</h1>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default BlogHome;
