import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";

import { BrowserView, MobileView } from "react-device-detect";

class Partners extends React.Component {
  render() {
    document.title = "Partners | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Partners</h2>
              <p className="medium-copy">
                <a
                  href="https://cryptogods.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/partners/cg-logo.png" alt="" />
                </a>
                <br />
                <a
                  href="https://t.me/thecryptogodsgroup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Crypto Gods
                </a>{" "}
                is an awesome place to make friends with crypto traders and
                others interested in crypto. It's welcoming to crypto newbies
                and doesn't have a lot of profanity.
                <br />
                <Button
                  className="btn btn-lg"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onClick={() =>
                    window.open("https://t.me/thecryptogodsgroup", "_blank")
                  }
                >
                  Visit The Crypto Gods
                </Button>
              </p>
              <p>
                <h3>Partner with CoinChomp</h3>
                CoinChomp is actively seeking to partner with brands in the
                crypto space. Reach out to us at biz@coinchomp.com and let us
                know what you have in mind!
              </p>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Partners;
