import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import Form from "react-bootstrap/Form";
import fb from "../../firebase.config";

import { BrowserView, MobileView } from "react-device-detect";

class Login extends React.Component {
  constructor(props) {
    console.log("constructor ");
    super(props);
    this.busy = false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.busy = true;
    this.setState({ busy: true });
    const email = document.getElementById("LoginForm.Email").value;
    const password = document.getElementById("LoginForm.Password").value;
    fb.auth.signInWithEmailAndPassword(email, password).then((cred) => {
      this.busy = false;
      this.setState({ busy: false });
      window.location = "/dashboard";
    });
  };

  render() {
    document.title = "Log In | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Log In</h2>
              <p>
                <Form id="LoginForm" onSubmit={this.handleSubmit}>
                  <Form.Group className="mb-4" controlId="LoginForm.Email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="eric@coinchomp.com"
                      size="md"
                      required={true}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="LoginForm.Password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" size="md" required={true} />
                  </Form.Group>
                  <Button variant="primary" type="submit" disabled={this.busy}>
                    {this.busy ? "Logging In..." : "Log In"}
                  </Button>
                  &nbsp; Or <a href="/signup">Sign Up!</a>
                </Form>
              </p>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Login;
