import React from 'react';

class AffiliateRedirect extends React.Component {

 render() {

    var url = window.location.href;
    var urlParts = url.split("/");
    var affiliateID = urlParts.slice(-1)[0];

    const affiliateIDs = {
        "gods" : "https://t.me/thecryptogodsgroup",
        "tube" : "https://www.youtube.com/channel/UC2dgxbtmpfoXyuFCro07IGA"
    };

    if(affiliateID in affiliateIDs){
        window.location = affiliateIDs[affiliateID.toLowerCase()];
    }else{
        window.location = "https://coinchomp.com/partners";
    }

    return (
      <div className="App"></div>
    );
  }
}

export default AffiliateRedirect;