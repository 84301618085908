import React from 'react';
import fb from '../../firebase.config';

class Logout extends React.Component {

  componentDidMount(){
    fb.auth.signOut().then(() => {
      window.location = "/";
    });
    setTimeout(function() { 
      window.location = "/";
    }, 10000);
  }

 render() {

    return (
      <div className="App"></div>
    );
  }
}

export default Logout;