import React from "react";
import EditorToolbar from "./EditorToolbar";

class Link extends React.Component {

  getLinkContentParagraph(link) {
    var c = "";
    var url = url = "https://coinchomp.com/c/" + link.id;
    if(link.data().hasOwnProperty('editionID')){
      url = "https://coinchomp.com/e/" + link.data().editionID + "/" + link.id;
    }
    if((link.data().hasOwnProperty('newsImpact') && link.data().newsImpact == "high") ||
    (link.data().hasOwnProperty('opinionValue') && link.data().opinionValue == "high") ||
    (this.props.importantWeight && link.data().weight > this.props.importantWeight)){
      c = link.data().content;
      if(link.data().content.length > 200){
        c = c.substring(0, 250) + "...";
      }
    }

    if(link.data().isHeadline){
      c = "";
    }

    if(this.props.print){
      c = link.data().content;
    }

    if(c.length > 0){
      return (
        <a href={url}><span className="link-content link-bottom"><br/>{c}</span></a>
      );
    }

    return (<span/>);
  }
  
  render() {
    const link = this.props.link;
    const isMobile = this.props.isMobile === true;

    if (link === undefined) {
      return <div>(empty)</div>;
    }
    let classes = "link-regular";
    let title = link.data().title;
    let linkContent = this.getLinkContentParagraph(link);
    if (link.data().isHeadline) {
      classes = "link-headline";
    }
    if (isMobile === true) {
      classes = classes + "-mobile";
    }

    if (link.data().chomp > 0 && link.data().isHeadline === false) {
      classes = classes + " link-featured";
    }
    if (
      (link.data().content.length === 0 && link.data().isHeadline === false) ||
      isMobile === true

    ) {
      classes = classes + " link-bottom";
    }

    let linkDate = link.data().createdAt.toDate();
    let nowDate = new Date();
    let secondsElapsed = (nowDate.getTime() - linkDate.getTime()) / 1000;

    if (link.data().isHeadline === false) {
      if (secondsElapsed < 3600 * 2) {
        //classes = classes+" link-new";
      } else if (secondsElapsed > 3600 * 24 && link.data().chomp === 0) {
        classes = classes + " link-old";
      }
    }

    let url = "/c/" + link.id;
    if(link.data().hasOwnProperty('editionID')){
      url = "/e/" + link.data().editionID + "/" + link.id;
    }else if(link.data().hasOwnProperty('linkID')){
      url = "/c/" + link.data().linkID;
    }

    if(this.props.print){
      //classes = classes + " link-print";
    }

    const getContent = () => {
      // if (
      //   isMobile === false &&
      //   link.data().isHeadline === false &&
      //   link.data().content.length > 0
      // ) {
      //   const trimmedContent = link.data().content.substring(0, 150) + "...";
      //   return (
      //     <p>
      //       <a
      //         href={url}
      //         target="_blank"
      //         className={classes}
      //         rel="noopener noreferrer"
      //       >
      //         {title}
      //       </a>
      //       <span className="link-content link-bottom">{trimmedContent}</span>
      //     </p>
      //   );
      // }

      // ************* NO IMAGE ************************************
      if (!link.data().imageURL || link.data().imageURL.length === 0) {
        if (isMobile === false && link.data().content.length > 0) {
      //  if (isMobile === false && link.data().content.length > 0) {
          return (
            <p>
              <a
                href={url}
                target="_blank"
                className={classes}
                rel="noopener noreferrer"
              >
                {title}
              </a>
              <span className="link-content link-bottom">
                {  this.getLinkContentParagraph(link)  }
              </span>
            </p>
          );
        } else {
          return (
            <a
              href={url}
              target="_blank"
              className={classes}
              rel="noopener noreferrer"
            >
              {title}
            </a>
          );
        }

      // ************* HAVE IMAGE ************************************
      } else {
        if (link.data().imagePosition === "top") {
          return (
            <a
              href={url}
              target="_blank"
              className={classes}
              rel="noopener noreferrer"
            >
              <img
                className="link-image"
                src={link.data().imageURL}
                style={{ maxWidth: "100%", maxHeight: "300px" }}
                alt=""
              />
              <br />
              {title}
              {linkContent}
            </a>
          );
        } else if (link.data().imagePosition === "left") {
          return (
            <a
              href={url}
              target="_blank"
              className={classes}
              rel="noopener noreferrer"
            >
              <img
                src={link.data().imageURL}
                style={{ float: "left", padding: "5px" }}
                width="25%"
                alt=""
              />{" "}
              {title}
              {linkContent}
            </a>
          );
        } else if (link.data().imagePosition === "right") {
          return (
            <a
              href={url}
              target="_blank"
              className={classes}
              rel="noopener noreferrer"
            >
              {title}{" "}
              <img
                src={link.data().imageURL}
                style={{ float: "right", padding: "5px" }}
                width="25%"
                alt=""
              />
              {linkContent}
            </a>
          );
        }
      }
    };
    const getEditorButtons = () => {
      if (!this.props.showEdit) return;
      return <EditorToolbar linkId={link.id} />;
    };
    return (
      <div className="link">
        {getContent()}
        {getEditorButtons()}
      </div>
    );
  }
}

export default Link;
