import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SidebarEmailCapture from "./SidebarEmailCapture";

class StoryContent extends React.Component {

  componentDidMount() {
    document.title = this.props.story.data().title;
    const descriptionText = this.props.story.data().content.substring(0, 100) + "...";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.content = descriptionText;
    } else {
        const head = document.head;
        const newMetaDesc = document.createElement('meta');
        newMetaDesc.name = "description";
        newMetaDesc.content = descriptionText;
        head.appendChild(newMetaDesc);
    }
}

  getImageContent(story){
    if(story.data().hasOwnProperty('imageURL') && story.data().imageURL.length > 0){
      return(
        <span>
          <img
            className="link-image"
            src={story.data().imageURL}
            style={{ maxWidth: "100%", maxHeight: "300px" }}
            alt=""
          />
          <br/>
        </span>
      );
    }else{
      return(
        <span/>
      );
    }
  }

  render() {
    const story = this.props.story;
    const destination = story.data().externalURL;
    const imageContent = this.getImageContent(story);

    if (story.data().content.length === 0) {
      window.location = destination;
      return <div></div>;
    }

    return (
      <div className="user-detail">
        <NavigationBar />
        <Container className="hero">
          <Button href="/" variant="link" size="sm">
            &lt; Back
          </Button>
          <br />
          <Jumbotron>
            <Row>
              <Col md={8} style={{ textAlign: "left" }}>
                {imageContent}
                <span style={{ fontSize: "28pt" }}>{story.data().title}</span>
                <br />
                <span style={{ fontSize: "20" }}>{story.data().content}</span>
                <br />
                <br />
              </Col>
              <Col md={4} style={{ textAlign: "left" }}>
                <SidebarEmailCapture />
              </Col>
            </Row>
          </Jumbotron>
        </Container>
      </div>
    );
  }
}

export default StoryContent;
