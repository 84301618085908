import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/navigation/NavigationBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class About extends React.Component {
  render() {
    document.title = "About CoinChomp | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={7} style={{ textAlign: "left" }}>
              <h1>About CoinChomp</h1>
              <p>
                CoinChomp is a content publisher in the crypto-currency space.
                We aggregate news, and produce fun educational content to help
                people learn about crypto.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default About;
