import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import Container from "react-bootstrap/Container";
import NavigationBar from "../navigation/NavigationBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fb from "../../firebase.config";

import "froala-editor/css/froala_style.min.css";

import { BrowserView, MobileView } from "react-device-detect";

const fetchPost = async (slug) => {
  console.log("slug == " + slug);
  return await fb.db
    .collection("blog_posts")
    .where("slug", "==", slug)
    .get()
    .then((snapshot) => {
      if (!snapshot.empty) {
        return snapshot.docs[0].data();
      } else {
        return null;
      }
    });
};

class ViewPost extends React.Component {
  constructor(props) {
    super(props);

    this.title = "";
    this.body = "";
    this.pubDate = new Date();
    this.slug = this.props.match.params.slug;
    this.authorDisplayName = "";
  }

  async componentDidMount() {
    this.slug = this.props.match.params.slug;
    this.setState({ slug: this.slug });
    const fetchedPost = await fetchPost(this.slug);
    if (fetchedPost !== null) {
      if (fetchedPost.published === false) {
        window.location.href = "/";
        return;
      }
      this.title = fetchedPost.title;
      this.body = fetchedPost.body;
      let date = fetchedPost.publishedAt.toDate();
      this.pubDate = date;
      this.authorDisplayName = fetchedPost.author.displayName;

      document.title = this.title + " | CoinChomp.com";

      this.setState({
        title: fetchedPost.title,
        body: fetchedPost.body,
        pubDate: date,
        authorDisplayName: fetchedPost.author.displayName,
      });
    }
  }

  render() {
    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>{this.title}</h2>
              <br />
              <div>
                <em>
                  Published{" "}
                  {this.pubDate.toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </em>{" "}
                by {this.authorDisplayName}
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: this.body }} />
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default ViewPost;
