import React from 'react';

const SidebarEmailCapture = () => {
  return (
    <iframe
      width="540"
      height="450"
      src="https://af44e864.sibforms.com/serve/MUIFAO2oa4YoRKpIaN5pdLZ53hHUhN9LuR8cn-LerwU4POuF7-Dixtg04k_fC23hn0ermrPMvEZnepvTR5JHLpJ5YOcIFKPfgbqKYHk801AEuU6gGt952ZP3pMULuhIPpg_cjMBC-gyv_MPRp1m4DOH7JzhUpm3ahXm-cvyBy8h7W2DgE6UpJ9pygH0UI72QYXcreRVtGd-KoW2n"
      frameBorder="0"
      scrolling="auto"
      allowFullScreen
      style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}
    />
  );
};

export default SidebarEmailCapture;
