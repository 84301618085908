import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import NavigationBar from "../../components/navigation/NavigationBar";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class UserDetail extends React.Component {
  render() {
    const user = this.props.user;
    var twitterScreenName = null;
    var twitterURL = null;
    if (user.data().twitterScreenName.length > 0) {
      twitterScreenName = user.data().twitterScreenName;
      twitterURL = `https://twitter.com/${twitterScreenName}`;
      twitterURL = twitterURL.replace("@", "");
    }
    return (
      <div className="user-detail">
        <NavigationBar />
        <Container className="hero">
          <Jumbotron>
            <Row>
              <Col md={2}>
                {twitterURL ? (
                  <Image
                    src={user.data().photoURL}
                    width="175px"
                    style={{ padding: "10px" }}
                    roundedCircle
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col md={10} style={{ textAlign: "left" }}>
                <span style={{ fontSize: "24pt" }}>
                  {twitterURL != null ? (
                    <a
                      href={twitterURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {twitterScreenName}
                    </a>
                  ) : (
                    user.data().name
                  )}
                </span>
                <br />
                <span style={{ fontSize: "12pt", color: "#CCCCCC" }}>
                  Last seen:{" "}
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  }).format(user.data().createdAt.toDate())}
                </span>
                <br />
                <span style={{ fontSize: "18" }}>
                  {user.data().bio ? user.data().bio : ""}
                </span>
                <br />
              </Col>
            </Row>
          </Jumbotron>
          <Row>
            <Col md={12}>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Curator Quality score: {user.data().score}</td>
                  </tr>
                  <tr>
                    <td>
                      Joined:{" "}
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(user.data().createdAt.toDate())}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserDetail;
