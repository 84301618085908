import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/navigation/NavigationBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";

import { BrowserView, MobileView } from "react-device-detect";

class Donate extends React.Component {
  render() {
    document.title = "Donate | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Donate to CoinChomp!</h2>
              <p className="medium-copy">
                CoinChomp is a small business with limited resources trying to
                stay afloat during a pandemic. Your donations are appreciated,
                and will go a long way to helping us keep going.
                <br />
                <br />
                <em>
                  Optional: if you donanted send an email to eric@coinchomp.com
                  and let me know the currency and amount and I'll give you a
                  shoutout on our TikTok, YouTube or Twitter channel.
                </em>
                <br />
                <br />
                Thank you for your kindness!
              </p>
              <hr />
              <p>
                <strong>Bitcoin</strong>
                <br />
                3Dgu7ay1cvMVvcRumHifJP6x4S6Eq2PTLo
              </p>
              <p>
                <strong>MetaMask (Ethereum, BSC, MATIC, HECO, etc)</strong>
                <br />
                0x0516B38b4adc12Cf192107f1ECBe09AE0b58741E
              </p>
              <p>
                <strong>Dogecoin</strong>
                <br />
                DJuFLFn3yhLhu8u6fkgN5j3wExy991XRi6
              </p>
              <p>
                <strong>Litecoin</strong>
                <br />
                MVSCUJD1pvh4L54LQWmYxhsGhJrzBDwQft
              </p>
              <p>
                <strong>ZCash</strong>
                <br />
                t1cpzZi4rX5YhdsKJPmgYt9mBEQzPbvRChS
              </p>
              <p>
                <strong>Polkadot</strong>
                <br />
                12swU7d38aEcKcpTnGagbgsQYuvVA2QrMva5nkq4mRXapU66
              </p>
              <p>
                <strong>Cardano</strong>
                <br />
                addr1qyvdr7sa8se2jtzkqnen3c5secsn920lp4nrjhy6v7h8jpyc50kwv6frtc4ssazuc8vstu7he8czk6fv2edzz0quer0qsj4m9f
              </p>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Donate;
