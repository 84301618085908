import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/navigation/NavigationBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class PrivacyPolicy extends React.Component {
  render() {
    document.title = "Privacy Policy | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container>
          <Row>
            <Col md={12} style={{ textAlign: "left" }}>
              <h2>Privacy Policy</h2>
              <p>
                CoinChomp does not harvest or sell any of your personal
                information.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PrivacyPolicy;
