import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Logo extends React.Component {
  render() {
    const isMobile = this.props.isMobile === true;

    let classes = "logo";

    if (isMobile === true) {
      classes = classes + "-mobile";
    }

    return (
      <Row>
        <Col md={12} style={{ textAlign: "center" }}>
          <img
            className={classes}
            src="/images/logo.png"
            alt="Coinchomp logo"
          />
        </Col>
      </Row>
    );
  }
}

export default Logo;
