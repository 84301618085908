import React from "react";
import { useDropzone } from "react-dropzone";

const defaultAccept = { "image/*": [] };

const Dropzone = ({
  onDropAccepted,
  onDropRejected,
  maxSize = 100000,
  accept = defaultAccept,
  maxFiles = 1,
  selectedUrl,
  onDeleteClick,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    acceptedFiles,
  } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    onDropRejected,
    onDropAccepted,
  });

  return (
    <div
      onClick={open}
      className={`w-100 d-flex align-items-center justify-content-center rounded ${
        isDragActive ? "bg-light" : ""
      }`}
      style={{
        minHeight: 100,
        border: "3px dashed var(--primary)",
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="p-4">
        <p className="p-0 m-0">
          {acceptedFiles.length
            ? acceptedFiles[0].name
            : selectedUrl
            ? selectedUrl
            : "Drag & Drop image"}
        </p>
        {selectedUrl ? (
          <button
            className="btn btn-outline-danger mt-4"
            onClick={onDeleteClick}
          >
            delete selected image
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Dropzone;
