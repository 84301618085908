import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import Container from "react-bootstrap/Container";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fb from "../../firebase.config";

import FroalaEditor from "froala-editor";

import "froala-editor/css/froala_editor.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/plugins.pkgd.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/image_manager.min.css";

import "froala-editor/js/froala_editor.min.js";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";

import BlogMenu from "./BlogMenu";

import { BrowserView, MobileView } from "react-device-detect";

const fetchPost = async (postID) => {
  let ref = fb.db.collection("blog_posts").doc(postID);
  let doc = await ref.get();
  if (doc.exists) {
    console.log("Document data:", doc.data());
    return doc.data();
  }
  console.log("Document data null");
  return null;
};

const makeImageID = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  let timestamp = Math.floor(Date.now() / 1000);
  return timestamp + "_" + result;
};

class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.titleUpdatesSlug = true;
    this.post = { title: "", body: "", slug: "" };
    this.title = "";
    this.body = "";
    this.slug = "";
    this.editor = null;
    this.published = false;
    this.saving = false;
    this.needSave = false;
    this.togglingPublish = false;
    this.deleting = false;
    this.userID = "";
    this.postID = this.props.match.params.id;
  }

  async componentDidMount() {
    document.title = "Edit Blog Post | CoinChomp.com";

    this.postID = this.props.match.params.id;
    this.setState({ postID: this.postID });
    const fetchedPost = await fetchPost(this.postID);
    if (fetchedPost.deleted) {
      window.location.href = "/dashboard";
    }
    if (fetchedPost !== null) {
      this.post = fetchedPost;
      this.title = fetchedPost.title;
      this.body = fetchedPost.body;
      this.slug = fetchedPost.slug;
      this.published = fetchedPost.published;

      let titleUpdatesSlug = true;

      if (this.title.length > 0) {
        titleUpdatesSlug = false;
        this.titleUpdatesSlug = titleUpdatesSlug;
      }

      const thisRef = this;

      let userID = fetchedPost.userID;

      const storageRef = fb.storage.ref().child("public/user/" + userID);

      console.log("about to try to list all for " + userID);

      // Find all the prefixes and items.
      const list = await storageRef.listAll();

      console.log("got these number of items " + list.items.length);

      console.log("%j", list.items.length);

      const downloadURL =
        "https://us-central1-coinchomp.cloudfunctions.net/listUserMedia?uid=" +
        userID;

      const editor = new FroalaEditor(
        "#editor",
        {
          pluginsEnabled: ["image", "link", "imageManager"],

          toolbarButtons: ["bold", "italic", "insertImage", "image"],

          imageManagerLoadURL: downloadURL,

          // Set the image upload parameter.
          imageUploadParam: "image_param",

          // Additional upload params.
          imageUploadParams: { id: "editor" },

          // Set request type.
          imageUploadMethod: "POST",

          // Set max image size to 5MB.
          imageMaxSize: 5 * 1024 * 1024,

          // Allow to upload PNG and JPG.
          imageAllowedTypes: ["jpeg", "jpg", "png"],

          events: {
            contentChanged: function () {
              thisRef.changeHandlerBody(this);
            },
            "image.beforeUpload": function (images) {
              const thisImage = images[0];
              console.log("before upload...");
              console.log("%j", thisImage);
              // Return false if you want to stop the image upload.

              if (
                thisRef.userID == null ||
                thisRef.userID === "undefined" ||
                thisRef.userID.length <= 0
              ) {
                console.log("no userID found when trying to upload image!");
                return false;
              }

              var imageRef = storageRef.child(
                "public/user/" +
                  thisRef.userID +
                  "/" +
                  makeImageID(6) +
                  "_" +
                  thisImage.name
              );

              imageRef.put(thisImage).then((snapshot) => {
                console.log("Uploaded a blob or file!");
              });

              return false;
            },
            "image.uploaded": function (response) {
              // Image was uploaded to the server.
              console.log("image uploaded!!!!");
            },
            "image.error": function (error, response) {
              // Bad link.
              if (error.code === 1) {
                console.log("image upload error: bad link");
              }

              // No link in upload response.
              else if (error.code === 2) {
                console.log("image upload error: no link");
              }

              // Error during image upload.
              else if (error.code === 3) {
                console.log("image upload error: error during upload");
              }

              // Parsing response failed.
              else if (error.code === 4) {
                console.log("image upload error: parsing response failed");
              }

              // Image too text-large.
              else if (error.code === 5) {
                console.log("image upload error: too large");
              }

              // Invalid image type.
              else if (error.code === 6) {
                console.log("image upload error: invalid type");
              }

              // Image can be uploaded only to same domain in IE 8 and IE 9.
              else if (error.code === 7) {
                console.log("image upload error: bad destination");
              }
            },
          },
        },
        function () {
          thisRef.editor = editor;

          if (thisRef.body === "") {
            thisRef.body = editor.html.get();
            thisRef.setState({ body: editor.html.get() });
            thisRef.userID = fetchedPost.userID;
          } else {
            editor.html.set(thisRef.body);
          }

          thisRef.setState({
            title: fetchedPost.title,
            slug: fetchedPost.slug,
            published: fetchedPost.published,
            userID: fetchedPost.userID,
            titleUpdatesSlug: titleUpdatesSlug,
          });
        }
      );
    }
  }

  updatePost = () => {
    this.saving = true;
    this.setState({ saving: true });
    console.log("yo updatePost");
    if (fb.auth.currentUser) {
      const user = fb.auth.currentUser;
      var updateBlogPost = fb.functions.httpsCallable("updateBlogPost");
      var postData = {};

      console.log("hiiiii");

      console.log("content 2 = " + this.editor.html.get(true));

      postData.title = this.title;
      postData.body = this.editor.html.get();
      postData.slug = this.slug;
      postData.published = this.published;
      updateBlogPost({
        userID: user.uid,
        postID: this.postID,
        postData: postData,
      }).then((result) => {
        window.location.href = "/edit-post/" + this.postID;
      });
    } else {
      console.log("no user");
    }
  };

  togglePublish = () => {
    this.togglingPublish = true;
    console.log("current publish value is " + this.published);
    var newValue = !this.published;
    console.log("new publish value is " + newValue);
    this.published = newValue;
    this.setState({ published: newValue, togglingPublish: true });
    this.updatePost();
  };

  deletePost = () => {
    this.deleting = true;
    this.setState({ deleting: true });
    console.log("deleting post");
    if (fb.auth.currentUser) {
      const user = fb.auth.currentUser;
      const deleteBlogPost = fb.functions.httpsCallable("deleteBlogPost");
      deleteBlogPost({ userID: user.uid, postID: this.postID }).then(
        (result) => {
          window.location.href = "/deleted-posts/";
        }
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting...");
    this.updatePost();
  };

  changeHandler = (evt) => {
    const name = evt.target.name;
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  formatSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/ +/g, "-")
      .replace(/-+/g, "-")
      .replace(/[^\w-]+/g, "");
  };

  changeHandlerTitle = (evt) => {
    const state = {};
    const value = evt.target.value;
    this.title = value;
    state.title = value;

    if (this.titleUpdatesSlug) {
      const slug = this.formatSlug(value);
      this.slug = slug;
      state.slug = slug;
    }

    if (this.title !== this.post.title) {
      this.needSave = true;
      state.needSave = true;
    } else {
      state.needSave = false;
    }

    this.setState(state);
  };

  changeHandlerBody = (editor) => {
    if (editor.html.get() !== this.body) {
      console.log("editor.html.get() ==> " + editor.html.get());
      console.log("this.body ==> " + this.body);
      this.needSave = true;
      this.setState({ needSave: true });
    } else {
      this.needSave = false;
      this.setState({ needSave: false });
    }
  };

  changeHandlerSlug = (evt) => {
    const value = evt.target.value;
    var state = {};

    this.titleUpdatesSlug = false;
    state.titleUpdatesSlug = false;

    this.slug = value;
    state.slug = value;

    if (this.slug !== this.post.slug) {
      this.needSave = true;
      state.needSave = true;
    } else {
      state.needSave = false;
    }

    this.setState(state);
  };

  render() {
    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              {/* <h2>Edit Post</h2> */}
              <BlogMenu />
              <p>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group className="mb-3" controlId="EditPostForm.Title">
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Blog Post Title"
                      size="lg"
                      value={this.title ?? ""}
                      onChange={this.changeHandlerTitle}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="EditPostForm.Slug">
                    <Form.Label>Slug</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      size="sm"
                      value={this.slug ?? ""}
                      onChange={this.changeHandlerSlug}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="EditPostForm.Body">
                    {/* <Form.Label>Body</Form.Label> */}
                    {/* <ReactQuill modules={modules} theme="snow" value={ this.body ?? "" } onChange={this.changeHandlerBody} /> */}
                    <div id="editor" className="fr-view"></div>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={this.needSave ? this.saving : true}
                  >
                    {this.needSave
                      ? this.saving
                        ? "Saving..."
                        : "Save"
                      : "Saved"}
                  </Button>
                  &nbsp;
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={this.saving}
                    onClick={this.togglePublish}
                  >
                    {this.togglingPublish
                      ? "..."
                      : this.published
                      ? "Unpublish"
                      : "Publish"}
                  </Button>
                  &nbsp;
                  <Button
                    variant="danger"
                    type="button"
                    disabled={this.deleting}
                    onClick={this.deletePost}
                  >
                    {this.deleting ? "Deleting..." : "Delete..."}
                  </Button>
                </Form>
              </p>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default EditPost;
