import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import Container from "react-bootstrap/Container";
import NavigationBar from "../navigation/NavigationBar";
import fb from "../../firebase.config";
import GetUserPosts from "./GetUserPosts";
import BlogMenu from "./BlogMenu";

import { BrowserView, MobileView } from "react-device-detect";

class UserPosts extends React.Component {
  constructor(props) {
    super(props);
    this.userID = "";
  }

  async componentDidMount() {
    document.title = "Your Posts | CoinChomp.com";
    fb.auth.onAuthStateChanged((user) => {
      if (user) {
        this.userID = user.uid;
        this.setState({ userID: user.id });
        console.log("%j", user);
        console.log("got this user id and it is " + user.uid);
      } else {
        window.location.href = "/login";
      }
    });
  }

  render() {
    if (this.userID.length <= 0) {
      return (
        <div className="App">
          <Container>
            <NavigationBar />
            <BrowserView>
              <h1>Posts</h1>
              <BlogMenu />
              <Footer />
            </BrowserView>
            <MobileView>
              <h1>Posts</h1>
              <FooterMobile />
            </MobileView>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="App">
          <NavigationBar />
          <Container>
            <BrowserView>
              <h1>Your Posts</h1>
              <BlogMenu />
              <GetUserPosts userID={this.userID} />
              <Footer />
            </BrowserView>
            <MobileView>
              <h1>Posts</h1>
              <FooterMobile />
            </MobileView>
          </Container>
        </div>
      );
    }
  }
}

export default UserPosts;
