import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";

import { BrowserView, MobileView } from "react-device-detect";

class BusinessInquiries extends React.Component {
  render() {
    document.title = "Business Inquiries | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Business Inquiries</h2>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <p>
                <h4>Become a Sponsor</h4>
                CoinChomp is looking for <em>cool</em> corporate sponsors in the
                crypto space. Click the button below or reach out to
                biz@coinchomp.com and let us know you'd like a{" "}
                <strong>media kit</strong>.
                <br />
                <Button
                  className="btn btn-lg"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onClick={() =>
                    window.open("mailto:biz@coinchomp.com", "_blank")
                  }
                >
                  Become a Sponsor
                </Button>
                <hr />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <p>
                <h4>Promote Your Project</h4>
                We offer different services depending on your budget. From 10
                second project shoutouts, to full project reviews and live
                developer interviews. Get in touch to let us know what you had
                in mind.
                <br />
                <Button
                  className="btn btn-lg"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onClick={() =>
                    window.open("mailto:biz@coinchomp.com", "_blank")
                  }
                >
                  Promote Your Project
                </Button>
              </p>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default BusinessInquiries;
