import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import fb from "../../firebase.config";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

const EditorToolbar = ({ linkId }) => {
  let editLink = "/edit-link/" + linkId;

  const history = useHistory();

  const [show, setShow] = useState(false);

  const handleCloseDeleteModal = () => setShow(false);
  const handleShowDeleteModal = () => setShow(true);

  const onDeleteClick = async () => {
    try {
      const deleteLink = fb.functions.httpsCallable("deleteLink");
      const currentUser = fb.auth.currentUser;
      await deleteLink({
        linkID: linkId,
        userID: currentUser?.uid,
      });
      handleCloseDeleteModal();
      toast.success("The link has been deleted.");
      window.location.reload();
    } catch (e) {
      toast.error("Something went wrong while deleting the link");
    }
  };

  return (
    <>
      <div className="btn-group btn-group-sm mb-3">
        {/*<button className="btn">*/}
        {/*  <i className="fa fa-arrow-up" aria-hidden="true"></i>*/}
        {/*</button>*/}
        {/*<button className="btn">*/}
        {/*  <i className="fa fa-arrow-down" aria-hidden="true"></i>*/}
        {/*</button>*/}
        <button
          className="btn btn-outline-danger"
          onClick={handleShowDeleteModal}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
        <button
          className="btn btn-outline-success"
          onClick={() => history.push(editLink)}
        >
          <i className="fa fa-pencil"></i>
        </button>
      </div>

      <Modal show={show} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this link?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This operation cannot be reverted</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary"
            onClick={handleCloseDeleteModal}
          >
            No, keep it
          </button>
          <button className="btn btn-danger" onClick={onDeleteClick}>
            Yes, delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditorToolbar;
