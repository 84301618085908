import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import fb from "../../firebase.config";

import { BrowserView, MobileView } from "react-device-detect";

class SetUsername extends React.Component {
  constructor(props) {
    console.log("constructor ");
    super(props);
    this.saving = false;
  }

  submitUpdateRequest = () => {
    this.saving = true;
    this.setState({ saving: true });
    if (fb.auth.currentUser) {
      const user = fb.auth.currentUser;
      const updateUserProfile = fb.functions.httpsCallable("updateUserProfile");
      const profileData = {};
      profileData.displayName = document.getElementById(
        "SignupForm.DisplayName"
      ).value;
      updateUserProfile({ userID: user.uid, profileData: profileData }).then(
        (result) => {
          this.saving = false;
          this.setState({ saving: false });
          if (result.data.didSucceed === true) {
            window.location.href = "/dashboard";
          }
        }
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting...");
    this.submitUpdateRequest();
  };

  render() {
    document.title = "Set Your Display Name | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={4} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Set Your Display Name</h2>
              <p>This will be visible to everyone.</p>
              <br />

              <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-3" controlId="SignupForm.DisplayName">
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Eric"
                    size="md"
                    required={true}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!!this.saving}
                >
                  {this.saving ? "Saving..." : "Save"}
                </Button>
              </Form>
            </Col>
          </Row>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default SetUsername;
