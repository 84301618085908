import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import NavigationBar from "../navigation/NavigationBar";
import fb from "../../firebase.config";

import { BrowserView, MobileView } from "react-device-detect";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    fb.auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
      } else {
        // User is signed out.
        window.location.href = "/login";
      }
    });
  }

  newPost() {
    if (fb.auth.currentUser) {
      const user = fb.auth.currentUser;

      console.log("newPost user exists = " + user.uid);
      console.log("creating new post...");
      var createBlogPost = fb.functions.httpsCallable("createBlogPost");
      createBlogPost({ userID: user.uid }).then((result) => {
        console.log("%j", result);
        window.location.href = "/edit-post/" + result.data.postID;
      });
    } else {
      console.log("newPost user DOES NOT exist");
    }
  }

  async componentDidMount() {
    document.title = "Dashboard | CoinChomp.com";
  }

  render() {
    return (
      <div className="App">
        <Container>
          <NavigationBar />
          <BrowserView>
            <h1>Dashboard</h1>
            <button onClick={this.newPost.bind(this)}>New Post</button>
            <br />
            <button>View Radar</button>
            <button>Use API</button>
            {/* <Footer /> */}
          </BrowserView>
          <MobileView>
            <h1>Dashboard</h1>
            {/* <FooterMobile /> */}
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Profile;
