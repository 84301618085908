import React from "react";
import { Nav } from "react-bootstrap";

const SideNav = () => {
  return (
    <Nav defaultActiveKey="/" className="flex-column bg-primary-dark vh-100">
      <Nav.Link href="/" className="text-white link-featured border-bottom">
        <i className="fa fa-newspaper-o mr-2"></i>
        Front Page
      </Nav.Link>
      <Nav.Link
        href="/dashboard"
        className="text-white link-featured border-bottom"
      >
        <i className="fa fa-bar-chart mr-2"></i>
        Dashboard
      </Nav.Link>
      <Nav.Link
        href="/logout"
        className="text-white link-featured border-bottom"
      >
        <i className="fa fa-sign-out mr-2"></i>
        Logout
      </Nav.Link>
    </Nav>
  );
};

export default SideNav;
