import React from "react";
import NavigationBar from "../navigation/NavigationBar";
import SidebarLayout from "../navigation/SidebarLayout";
import EditLinkForm from "./EditLinkForm";

const EditLink = () => {
  return (
    <div className="App">
      <NavigationBar />

      <SidebarLayout>
        <EditLinkForm />
      </SidebarLayout>
    </div>
  );
};

export default EditLink;
