import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/navigation/NavigationBar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";

import { BrowserView, MobileView } from "react-device-detect";

class Books extends React.Component {
  render() {
    document.title = "Reccomended Books | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container className="hero">
          <Row>
            <Col md={8} style={{ textAlign: "left", paddingTop: "20px" }}>
              <h2>Books We Reccomend</h2>
              <hr />
              <p>
                <h3>Mastering Ethereum</h3>
                A great introduction to ethereum and ethereum-like blockchains
                and smart contract development, co-authored by Andreas
                Antonopolous and Polkadot Founder Dr. Gavin Wood.
                <br />
                <Button
                  className="btn btn-lg"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onClick={() =>
                    window.open("https://amzn.to/3fi67xw", "_blank")
                  }
                >
                  Buy On Amazon
                </Button>
              </p>
            </Col>
          </Row>
          <hr />
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Books;
