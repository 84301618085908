import "../../App.css";
import "firebase/firestore";
import "firebase/auth";
import Link from "./Link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fb from "../../firebase.config";
import React from "react";
import Logo from "./Logo";

const getDayName = (dateStr, locale) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "long" });
};

const fetchLinks = async () => {
  let ref = fb.db.collection("links");
  let snapshot = await ref
    .where("reviewState", "==", "approved")
    .orderBy("weight", "desc")
    .limit(100)
    .get();
  if (!snapshot.empty) {
    return snapshot.docs.map((doc) => doc);
  }
  return [];
};

const fetchLatestEdition = async (latestPublished = false) => {
  let ref = fb.db.collection("editions");
  let snapshot = await ref
    .orderBy("createdAt", "desc")
    .limit(7)
    .get();

    if (!snapshot.empty) {
      for(const edition of snapshot.docs){
          if(latestPublished){
              if(edition.data().state === "published"){
                  return edition;
              }else{
                  continue;
              }
          }else{
              return edition;
          }
      }
  }
  return null;
};

const fetchStoriesForEdition = async (editionID) => {
  try {
    const storiesSnapshot = await fb.db.collection('editions')
        .doc(editionID)
        .collection('stories')
        .orderBy('weight', 'desc')
        .get();
    const stories = storiesSnapshot.docs.map(doc => doc);
    return stories;
  } catch (error) {
      console.error('Error fetching stories:', error);
      throw error;
  }
}

class GetLinksMobile extends React.Component {
  constructor(props) {
    super(props);
    this.latestEdition = null;
    this.rawLinks = [];
    this.regularLinks = [];
    this.headlineLinks = [];
    this.topics = {};
  }

  async componentDidMount() {
    this.latestEdition = await fetchLatestEdition(true);
    if(this.latestEdition){
      this.rawLinks = await fetchStoriesForEdition(this.latestEdition.id);
    }else{
      this.rawLinks = await fetchLinks();
    }
    this.setState({
      rawLinks: this.rawLinks,
      latestEdition: this.latestEdition
    });
  }

  render() {

    const headlineColumn = [];
    const mainColumn = [];

    let date = new Date();
    if(this.latestEdition){
      date = this.latestEdition.data().createdAt.toDate();
    }

    const dayName = getDayName(date.toString());
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNameIndex = date.getMonth();

    for (const link of this.rawLinks) {
   
      if(link.data().isHeadline){
        headlineColumn.push(link);
      }else{
        mainColumn.push(link);
      }

    }

    return (
      <div>
        <Row className="cc-small-text">
          <Col md={12} style={{ textAlign: "center" }}>
            <Logo isMobile={true} />
            {dayName} {monthNames[monthNameIndex]} {date.getDate()},{" "}
            {date.getFullYear()}
            <br />
            <br />
          </Col>
          <Col md={12} style={{ textAlign: "center" }}>
            {headlineColumn.map((doc) => (
              <Link link={doc} isMobile={true} />
            ))}
          </Col>
          <Col md={12} style={{ textAlign: "center" }}>
            {mainColumn.map((doc) => (
              <Link link={doc} isMobile={true} />
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}
export default GetLinksMobile;
