import "../../App.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import StoryContent from "./StoryContent";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import { useDocument } from "react-firebase-hooks/firestore";

import { BrowserView, MobileView } from "react-device-detect";

const StoryHook = (editionID, storyID) => {
  const [value, loading, error] = useDocument(
    firebase.firestore().doc("editions/" + editionID + "/stories/" + storyID),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  return (
    <div>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span style={{ opacity: 0.25 }}>Loading...</span>}
      {value && <StoryContent story={value} />}
    </div>
  );
};

function GetStoryContent() {

  const url = window.location.href;
  const urlParts = url.split('/');
  const storyID = urlParts.slice(-1)[0];
  const editionID = urlParts.slice(-2)[0]; // Get the second-to-last element

  return (
    <div className="App">
      {StoryHook(editionID, storyID)}
      <BrowserView>
        <Footer />
      </BrowserView>
      <MobileView>
        <FooterMobile />
      </MobileView>
    </div>
  );
}

export default GetStoryContent;
