import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import Container from "react-bootstrap/Container";
import NavigationBar from "../navigation/NavigationBar";
import fb from "../../firebase.config";

import { BrowserView, MobileView } from "react-device-detect";

const fetchUserProfile = async (userID) => {
  let ref = fb.db.collection("users").doc(userID);
  let doc = await ref.get();
  if (doc.exists) {
    console.log("Document data:", doc.data());
    return doc.data();
  }
  console.log("Document data null");
  return null;
};

class Dashboard extends Component {
  // newPost() {
  //   if (fb.auth.currentUser) {
  //     const user = fb.auth.currentUser;
  //     console.log("newPost user exists = " + user.uid);
  //     console.log("creating new post...");
  //     const createBlogPost = fb.functions.httpsCallable("createBlogPost");
  //     createBlogPost({ userID: user.uid }).then((result) => {
  //       if (result.data.postID === "undefined") {
  //         return;
  //       }
  //       window.location.href = "/edit-post/" + result.data.postID;
  //     });
  //   } else {
  //     console.log("newPost user DOES NOT exist");
  //   }
  // }

  async componentDidMount() {
    document.title = "Dashboard | CoinChomp.com";
    console.log("mounted!");
    fb.auth.onAuthStateChanged(async function (user) {
      if (user) {
        // User is signed in.
        let userProfile = await fetchUserProfile(user.uid);
        if (userProfile) {
          if (!("username" in userProfile)) {
            window.location.href = "/set-username";
            return;
          }
          if (!("displayName" in userProfile)) {
            window.location.href = "/set-display-name";
          }
        }
      } else {
        // User is signed out.
        window.location.href = "/login";
      }
    });
  }

  async componentDidUpdate() {
    console.log("updated!");
  }

  render() {
    return (
      <div className="App">
        <NavigationBar />
        <Container>
          <BrowserView>
            <h1>Dashboard</h1>
            <a href="/posts" className="btn btn-primary">
              Your Posts
            </a>
            &nbsp;
            <br />
            <Footer />
          </BrowserView>
          <MobileView>
            <h1>Dashboard</h1>
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Dashboard;
