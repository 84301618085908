import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/navigation/NavigationBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class TermsOfUse extends React.Component {
  render() {
    document.title = "Terms of Use | coinchomp.com";

    return (
      <div className="App">
        <NavigationBar />
        <Container>
          <Row>
            <Col md={12} style={{ textAlign: "left" }}>
              <h2>Terms of Use</h2>
              <p>
                By using CoinChomp.com you agree that CoinChomp LLC owns any
                data created during the course of curating content or performing
                actions within the mobile or web apps.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default TermsOfUse;
