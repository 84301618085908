import '../../App.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Footer from '../navigation/Footer';
import UserDetail from '../../components/user/UserDetail';
import { useDocument } from 'react-firebase-hooks/firestore';

const UserHook = (userID) => {
  const [value, loading, error] = useDocument(
    firebase.firestore().doc("users/" + userID ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  return (
    <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span style={{opacity:0.25}}>Loading user...</span>}
        {value && <UserDetail user={value} /> }
    </div>
  );
};

// Twitter for iOS does not support Universal links
function tryOpenInApp(userID){
  window.location = "cryptometheus://user/" + userID;
}

function GetUserDetail() {

  var url = window.location.href;
  var urlParts = url.split("/");
  const userID = urlParts.slice(-1)[0];

  setTimeout(function() { 
    tryOpenInApp(userID);  
  }, 1500);

  return (
    <div className="App">
        { UserHook(userID) }
        <Footer />
    </div>
  );
}

export default GetUserDetail;
