import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fb from "../../firebase.config";

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.print = false;
  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);
    this.print = urlParams.get('p') === 'true';

    let link = document.getElementById("authLink");

    if (fb.auth.currentUser) {
      link.innerHTML = "Logout";
      link.setAttribute("href", "/logout");
    } else {
      link.innerHTML = "Login";
      link.setAttribute("href", "/login");
    }

    fb.auth.onAuthStateChanged((user) => {
      if (user) {
        link.innerHTML = "Logout";
        link.setAttribute("href", "/logout");
      } else {
        link.innerHTML = "Login";
        link.setAttribute("href", "/login");
      }
    });

    this.setState({
      print: this.print
    });
  }

  render() {
    return (
      <footer className="my-md-5 pt-md-1">
        <Row className={this.print ? 'print-hidden' : ''}>
          <Col md={12} style={{ textAlign: "center" }}>
            <a
              style={{ color: "inherit" }}
              href="https://t.me/coinchomp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-telegram-plane"></i>
            </a>
            &nbsp;
            <a
              style={{ color: "inherit" }}
              href="https://www.youtube.com/c/CoinChomp?sub_confirmation=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
            &nbsp;
            <a
              style={{ color: "inherit" }}
              href="https://www.tiktok.com/@CoinChomp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i>
            </a>
            &nbsp;
            <a
              style={{ color: "inherit" }}
              href="https://instagram.com/CoinChomp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            &nbsp;
            <a
              style={{ color: "inherit" }}
              href="https://twitter.com/CoinChomp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>

            &nbsp;
            <a
              style={{ color: "inherit" }}
              href="mailto:contact@coinchomp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-envelope"></i>
            </a>

          </Col>
        </Row>

        <Row className={this.print ? 'print-hidden' : ''}>
          <Col md={12} style={{ textAlign: "center" }}>
            <a
              style={{ color: "inherit" }}
              href="https://daily.coinchomp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="coinchomp-primary">
                <strong>Get News in Your Inbox</strong>
              </span>
            </a>
            &nbsp;|&nbsp;
            <a style={{ color: "inherit" }} href="https://shop.coinchomp.com">
              Funny Crypto T-Shirts & Mugs
            </a>
            &nbsp;|&nbsp;
            <a id="authLink" style={{ color: "inherit" }} href="/login">
              Sign in
            </a>
          </Col>
        </Row>

        <Row>
          <Col md={12} style={{ textAlign: "center" }}>
            <span style={{ fontSize: "10pt", opacity: 0.25 }}>
              © 2013-{new Date().getFullYear()}{" "}              
              <a
                style={{ color: "inherit" }}
                href="https://coinchomp.com"
                target="_blank"
                rel="noopener noreferrer"
              >CoinChomp.com</a>
            </span>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
