import "../../App.css";
import "firebase/firestore";
import "firebase/auth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fb from "../../firebase.config";
import React from "react";

const fetchPostsForUser = async (userID) => {
  console.log("fetching posts for " + userID);
  let ref = fb.db.collection("blog_posts");
  let snapshot = await ref
    .where("userID", "==", userID)
    .where("deleted", "==", true)
    .orderBy("createdAt", "desc")
    .get();
  if (!snapshot.empty) {
    return snapshot.docs.map((doc) => doc);
  }
  return [];
};

class GetDeletedPosts extends React.Component {
  constructor(props) {
    super(props);
    this.userID = props.userID;
    this.posts = [];
  }

  async componentDidMount() {
    this.posts = await fetchPostsForUser(this.userID);
    this.setState({ posts: this.posts });
  }

  async componentDidUpdate() {
    if (this.userID.length > 0 && this.posts.length === 0) {
      this.posts = await fetchPostsForUser(this.userID);
      if (this.posts.length > 0) {
        this.setState({ posts: this.posts });
      }
    }
  }

  render() {
    console.log(this.posts.length);
    if (this.userID === "" || this.posts.length === 0) {
      return <div></div>;
    } else {
      return (
        <div>
          <Row className="" style={{ paddingTop: "20px" }}>
            <Col className="" md={6}>
              <table className="table bordered">
                {this.posts.map((doc) => (
                  <tr>
                    <td>
                      <a href={"/edit-post/" + doc.id}>
                        {doc.data().title.length > 0
                          ? doc.data().title
                          : "Untitled"}
                      </a>
                    </td>
                  </tr>
                ))}
              </table>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
export default GetDeletedPosts;
