import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../navigation/Footer";
import FooterMobile from "../navigation/FooterMobile";
import Container from "react-bootstrap/Container";
import GetLinks from "./GetLinks";
import GetLinksMobile from "./GetLinksMobile";

import { BrowserView, MobileView } from "react-device-detect";

class Home extends React.Component {
  render() {
    return (
      <div className="App">
        <Container>
          <BrowserView>
            <GetLinks />
            <Footer />
          </BrowserView>
          <MobileView>
            <GetLinksMobile />
            <FooterMobile />
          </MobileView>
        </Container>
      </div>
    );
  }
}

export default Home;
